import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Avatar,
  TextField,
  Button,
  Stack,
  IconButton,
  Dialog,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getTicketData, addCommentToTicket } from '../../../services/tickets';
import { NeutralsColor } from '../../../core-components/theme/color-palette/color-palette.theme';
import moment from 'moment';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';

interface CommentData {
  id?: number;
  user: {
    initials: string;
    name: string;
  };
  date: string;
  comment: string;
  reverse?: boolean;
  editable?: boolean;
}

const Comment = ({ user, date, comment, editable, reverse }: CommentData) => {
  return (
    <Box sx={{ display: 'flex', gap: 4, flexDirection: reverse ? 'row-reverse' : 'row' }}>
      <Stack direction="column" gap={1}>
        <Avatar sx={{ width: 40, height: 40, background: '#183650', fontSize: '14px' }}>
          {user.initials}
        </Avatar>
        <Typography variant="body2" sx={{ fontSize: '15px', color: '#00000061' }}>
          {user.name}
        </Typography>
      </Stack>
      <Box
        sx={{
          flexGrow: 1,
          border: `1px solid ${NeutralsColor[200]}`,
          borderRadius: '10px',
          position: 'relative',
        }}
        p={3}
      >
        <PushPinOutlinedIcon
          sx={
            reverse
              ? {
                  position: 'absolute',
                  fontSize: '15px',
                  top: '5px',
                  left: '5px',
                  color: NeutralsColor[300],
                  transform: 'rotate(-45deg)',
                }
              : {
                  position: 'absolute',
                  fontSize: '15px',
                  top: '5px',
                  right: '5px',
                  color: NeutralsColor[300],
                  transform: 'rotate(45deg)',
                }
          }
        />
        <Box
          sx={{
            backgroundColor: editable ? '#f5f5f5' : 'white',
            padding: 1.5,
            borderRadius: '10px',
            border: editable ? '1px solid #ddd' : 'none',
            position: 'relative',
          }}
        >
          {editable && (
            <IconButton
              sx={{ position: 'absolute', top: 5, right: 5, fontSize: '15px' }}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          )}
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ fontSize: '15px', color: '#00000061' }}
          >
            {comment}
          </Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            sx={{
              display: 'block',
              textAlign: 'right',
              mt: 1,
              color: '#00000061',
              fontSize: '12px',
            }}
          >
            {date}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const CommentSection = ({
  alert,
  open,
  handleClose,
  ticketId,
}: {
  alert: any;
  open: boolean;
  handleClose: Function;
  ticketId: string;
}) => {
  const [comment, setComment] = useState('');
  //@ts-ignore
  const [ticketData, setTicketData] = useState<any>(null);
  const [commentsData, setCommentsData] = useState<any[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      getTicketData(ticketId, '').then(data => {
        setTicketData(data);
        console.log('events  : ', data);
        setCommentsData(data?.events?.filter((event: any) => event.event == 'comment'));
      });
    }
  }, [open, refresh]);

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const addCommentToTicketFunc = () => {
    addCommentToTicket(ticketId, '', comment).then(data => {
      setRefresh(!refresh);
    });
    setComment('');
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      PaperProps={{
        style: {
          width: '70%', // Increase the width
          maxWidth: '70%', // Ensure the dialog doesn't grow beyond this width
          borderRadius: '15px',
          maxHeight: '90%',
          boxSizing: 'border-box',
        },
      }}
    >
      <Box sx={{ padding: 4, width: '100%', boxSizing: 'border-box' }}>
        {/* Problem Name and Description */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" sx={{ fontSize: '20px' }}>
            Comments
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Typography
              variant="subtitle2"
              sx={{ fontWeight: 'bold', color: '#16324A', fontSize: '14px' }}
            >
              {alert.heading?.eng}
            </Typography>
            {/* <Typography variant="body2" color="textSecondary" sx={{ ml: 1, fontStyle: 'italic' }}>
              (Edited)
            </Typography> */}
          </Box>
          <Typography variant="body2" sx={{ mt: 1, fontSize: '14px', color: '#16324A' }}>
            {alert.description?.eng}
          </Typography>
        </Box>

        {/* <Divider /> */}

        {/* Render Comments from Array */}
        {commentsData?.length > 0 ? (
          <Box sx={{ mt: 2, maxHeight: '300px', overflowY: 'scroll' }} pr={2}>
            <Stack direction="column" gap={3}>
              {commentsData?.map((commentData: any, commentIndex: number) => (
                <Comment
                  user={{ initials: 'KA', name: 'kshitiz' }}
                  date={moment(commentData.time, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format(
                    'DD/MM/YYYY | hh:mm a'
                  )}
                  comment={commentData.comment}
                  editable={commentData.editable}
                  reverse={commentIndex % 2 == 1 ? true : false}
                />
              ))}
            </Stack>
          </Box>
        ) : (
          <Box>No Comments</Box>
        )}

        {/* Comment Input Section */}
        <Box sx={{ mt: 3 }}>
          <TextField
            placeholder="Type your comment here"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={comment}
            onChange={handleCommentChange}
          />
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Button disabled variant="outlined" sx={{ textTransform: 'none' }}>
              Rephrase Using AI
            </Button>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              variant="outlined"
              onClick={() => setComment('')}
              sx={{ textTransform: 'none' }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={addCommentToTicketFunc}
              sx={{ textTransform: 'none' }}
            >
              Submit
            </Button>
          </Stack>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CommentSection;
