import {
  Box,
  Stack,
  Divider,
  Popover,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import moment from 'moment';
import { WIDGET_THEMES } from '../../../constants/widgetConfig.constants';
import Chip from '../../../core-components/theme/components/Chips/PrimaryChips';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import HistoryIcon from '@mui/icons-material/History';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useEffect, useState } from 'react';
import CommentSection from './CommentSection';
import { getAllTicketIdsOfAlert } from '../../../services/tickets';
import { Neutrals } from '../../../core-components/theme/color-palette/colors';

export default function ManualEntryRow({ alert, theme, refreshRow }: any) {
  const fontColor = WIDGET_THEMES[theme || 'default'].fontColor;
  const background = WIDGET_THEMES[theme || 'default'].backgroundColor;
  const lightBackground = WIDGET_THEMES[theme || 'default'].lightBackgroundColor;
  const lightBorder = WIDGET_THEMES[theme || 'default'].lightBorderColor;
  const [openCommentSection, setOpenCommentSection] = useState(false);
  const [ticketIds, setTicketIds] = useState<string[]>([]);

  useEffect(() => {}, [refreshRow]);

  useEffect(() => {
    getAllTicketIdsOfAlert(alert._id, '').then(data => {
      setTicketIds(data);
    });
  }, []);

  // State for Popover
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Handle popover open/close
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box sx={{ boxSizing: 'border-box' }}>
      <Box
        border={`1px solid ${background}`}
        borderRadius={2}
        sx={{
          overflow: 'hidden',
        }}
      >
        <Stack direction="row">
          <Box sx={{ width: '57%', color: fontColor, fontWeight: 500, fontSize: '12px' }} p={2}>
            {alert.heading?.eng}
          </Box>
          <Divider orientation="vertical" flexItem sx={{ background: lightBorder }} />
          <Box sx={{ width: '10%', background: lightBackground }} p={1}>
            <Stack direction="column">
              <Box sx={{ fontSize: '12px', fontWeight: 500, color: fontColor }}>
                {alert.plantNames}
              </Box>
              <Box sx={{ fontSize: '10px' }}>
                {alert?.lastTicket?.createdAt
                  ? moment(alert?.lastTicket?.createdAt, 'YYYY-MM-DDTHH:mm:ss.SSS')?.format(
                      'DD/MM/YYYY hh:mm a'
                    )
                  : '-'}
              </Box>
            </Stack>
          </Box>
          <Divider orientation="vertical" flexItem sx={{ background: lightBorder }} />
          <Box sx={{ width: '30%', background: lightBackground }} p={2}>
            <Stack direction="column" gap={2}>
              <Box sx={{ color: fontColor, fontWeight: 500, fontSize: '12px' }}>
                Plant process issues
              </Box>
              <Stack direction="row" gap={1}>
                {alert.issueType?.map((type: any, index: number) => {
                  return (
                    <Chip
                      key={index}
                      label={type}
                      variant="outlined"
                      sx={{
                        fontSize: '9px',
                        color: fontColor,
                        borderColor: background,
                      }}
                    />
                  );
                })}
                {alert.issueType == null || alert.issueType?.length < 1 ? (
                  <Chip
                    label="None"
                    variant="outlined"
                    sx={{
                      fontSize: '9px',
                      color: fontColor,
                      borderColor: background,
                    }}
                  />
                ) : null}
              </Stack>
            </Stack>
          </Box>
          <Divider orientation="vertical" flexItem sx={{ background: lightBorder }} />
          <Box sx={{ width: '3%', background: lightBackground }} p={1}>
            {/* IconButton for vertical ellipsis */}

            <MoreVertIcon
              sx={{
                color: fontColor,
                cursor: 'pointer', // This changes the cursor to a pointer on hover
              }}
              onClick={(event: any) => {
                handleClick(event);
              }}
            />

            {/* Popover for more options */}
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem
                sx={{ color: Neutrals[500] }}
                disabled
                onClick={() => console.log('Edit clicked')}
              >
                <ListItemIcon>
                  <EditOutlinedIcon sx={{ width: '20px', height: '20px', color: Neutrals[300] }} />
                </ListItemIcon>
                <ListItemText
                  primary="Edit"
                  sx={{
                    '& .MuiListItemText-primary': {
                      fontSize: '14px', // Adjust the font size here
                    },
                  }}
                />
              </MenuItem>
              <MenuItem sx={{ color: Neutrals[500] }} onClick={() => setOpenCommentSection(true)}>
                <ListItemIcon>
                  <CommentOutlinedIcon
                    sx={{ width: '20px', height: '20px', color: Neutrals[300] }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Comment"
                  sx={{
                    '& .MuiListItemText-primary': {
                      fontSize: '14px', // Adjust the font size here
                    },
                  }}
                />
              </MenuItem>
              <MenuItem
                sx={{ color: Neutrals[500] }}
                disabled
                onClick={() => console.log('History clicked')}
              >
                <ListItemIcon>
                  <HistoryIcon sx={{ width: '20px', height: '20px', color: Neutrals[300] }} />
                </ListItemIcon>
                <ListItemText
                  primary="History"
                  sx={{
                    '& .MuiListItemText-primary': {
                      fontSize: '14px', // Adjust the font size here
                    },
                  }}
                />
              </MenuItem>
            </Popover>
          </Box>
        </Stack>
      </Box>
      <CommentSection
        open={openCommentSection}
        handleClose={() => {
          setOpenCommentSection(false);
        }}
        alert={alert}
        ticketId={ticketIds[0]}
      />
    </Box>
  );
}
