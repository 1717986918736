import { Box, Stack } from '@mui/material';
import { getOpenAlertsForClient, getClosedAlertsForClient } from '../../services/tickets';
import { getPlantsForUser } from '../../services/tickets';
import { getUserDetails } from '../../services/profile';
import { getManualEntryWidgetData } from '../../services/dashboard/widget';

import { useEffect, useState } from 'react';
import ManualEntryRow from './subComponents/ManualEntryRow';
import { WIDGET_THEMES } from '../../constants/widgetConfig.constants';

export default function ManualEntryWidget({ widgetId, widgetStructure, refresh }: any) {
  const [openAlerts, setOpenAlerts] = useState([]);
  const [closedAlerts, setClosedAlerts] = useState([]);
  const [plantsForUser, setPlantsForUser] = useState([]);
  const [userId, setUserId] = useState('');
  const [widgetData, setWidgetData] = useState<any>(null);
  const [background, setBackground] = useState('#ddd');
  const [fontColor, setFontColor] = useState('#ddd');

  const getAllPlantIds = () => plantsForUser.map((plant: any) => plant.plantId);

  useEffect(() => {
    getUserDetails().then((data: any) => {
      setUserId(data?.data?.user?._id);
    });
  }, []);

  useEffect(() => {
    if (userId != '') {
      getPlantsForUser(userId!).then(data => {
        setPlantsForUser(data);
      });
    }
  }, [userId]);

  useEffect(() => {
    if (userId != '') {
      getOpenAlertsForClient(userId, getAllPlantIds()).then(data => {
        setOpenAlerts(data);
      });
      getClosedAlertsForClient(userId, getAllPlantIds()).then(data => {
        setClosedAlerts(data);
      });
    }
  }, [plantsForUser]);

  useEffect(() => {
    getManualEntryWidgetData(widgetId, widgetStructure).then(data => {
      setWidgetData(data);
      setBackground(WIDGET_THEMES[data.widgetLook?.theme || 'default'].backgroundColor);
      setFontColor(WIDGET_THEMES[data.widgetLook?.theme || 'default'].fontColor);
    });
  }, [refresh]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        border: `1px solid ${background}`,
        overflow: 'hidden',
        boxSizing: 'border-box',
        background: '#fff',
      }}
      borderRadius={3}
      p={3}
      pb={0}
    >
      <Stack direction="column" gap={1} sx={{ height: '100%', width: '100%' }}>
        <Box sx={{ height: '20px', color: fontColor, fontWeight: 600 }}>
          {widgetData?.widgetName}
        </Box>
        <Stack
          sx={{ width: 'inherit', color: '#484F54', fontSize: '14px', boxSizing: 'border-box' }}
          direction="row"
          mt={1}
          px={2}
        >
          <Box sx={{ width: '55.5%' }}>Description</Box>
          <Box sx={{ width: '10%' }}>Plant Name</Box>
          <Box sx={{ width: '30%' }}>Type & Tags</Box>
        </Stack>
        <Stack sx={{ overflowY: 'scroll', height: `calc(100% - 30px)` }} gap={2} mt={2}>
          {openAlerts.map(alert => {
            return (
              <ManualEntryRow
                alert={alert}
                theme={widgetData?.widgetLook?.theme}
                refreshRow={refresh}
              />
            );
          })}
          {closedAlerts.map(alert => {
            return (
              <ManualEntryRow
                alert={alert}
                theme={widgetData?.widgetLook?.theme}
                refreshRow={refresh}
              />
            );
          })}
        </Stack>
      </Stack>
    </Box>
  );
}
